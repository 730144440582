import * as Sentry from "@sentry/nuxt";

console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: "https://5521b36a5cb509982cdffc871279de13@o4507945401647104.ingest.de.sentry.io/4507945409380432",

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
	});
}

